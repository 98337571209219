<template>
  <div id="app">

    <app-preloader v-if="showLoader"/>

    <app-feedback :feedbackOpen=feedbackOpen @closeFeedback=closeFeedback />
    <app-header :changedHeader=changedHeader />
    <transition name="fadeMain">
      <router-view @changeHeader=changeHeader @openFeedback=openFeedback />
    </transition>
    
    <app-footer id="footer" v-if="footerHide"/>
  </div>
</template>

<script>
import AppPreloader from './components/Preloader.vue'
import AppFeedback from './components/Feedback.vue'
import AppHeader from './components/Header.vue'
import AppFooter from './components/Footer.vue'
import 'wowjs/css/libs/animate.css'
import WOW from 'wowjs';

export default {
  components: {
    AppPreloader,
    AppFeedback,
    AppHeader,
    AppFooter
  },
  created() {
    this.$lang = 'uz'
    sessionStorage.lang  = this.$lang
    this.$store.dispatch('loadNews')
    this.$store.dispatch('loadCatalog')
    this.$store.dispatch('loadVideos')
    this.checkFooter()

    if(sessionStorage.loader) {
      this.showLoader = false
    } else {
      setTimeout(() => {
        this.showLoader = false
        this.initWOW()
        sessionStorage.loader = true
      }, 3000)
    }
  },
  data() {
    return {
      changedHeader: false,
      feedbackOpen: false,
      showLoader: true,
      footerHide: true,
    }
  },
  methods: {
    changeHeader(data) {
      this.changedHeader = data
    },
    openFeedback() {
      this.feedbackOpen = true
    },
    closeFeedback() {
      this.feedbackOpen = false
    },
    checkFooter() {
      if (this.$route.name == 'Products' || this.$route.name == 'ProductSingle') {
        this.footerHide = false
      } else {
        this.footerHide = true
      }
    },
    initWOW() {
      this.$nextTick(() => {
        const wow = new WOW.WOW({
          boxClass: 'wow',
          animateClass: 'animated',
          offset: 0,
          mobile: false,
          live: false
        });
        wow.init();
      })
    }
  },

  watch: {
    $route() {
      this.initWOW()
      this.checkFooter()
    },
    
  }
}
</script>

<style>

@media (min-width: 768px) {
  .fadeMain-enter-active, .fadeMain-leave-active {
    transition: .7s all;
  }

  .fadeMain-enter-to, .fadeMain-leave {
    opacity: 1;
    transform: none;
  }

  .fadeMain-enter, .fadeMain-leave-to {
    opacity: 0;
    transform: translateX(100px);
  }
}
</style>

